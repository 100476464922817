#cv{
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
  }

 #downloadicon{
    display:flex;
    color: white;
    font-size: 1.3rem;
 }
  
  .cv {
    position: relative;
    padding: 20px 50px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    width: 200px;
    overflow: hidden;
    border-radius: 40px;
    margin-bottom: 5rem;
    text-align: center;
    font-weight: 500;
  }
  
  .cv span {
    position: relative;
    color: #fff;
    font-size: 20px;
    font-family: Arial;
    letter-spacing: 4px;
    z-index: 1;
  }
  
  .cv .liquid {
    position: absolute;
    top: -80px;
    left: 0;
    width: 200px;
    height: 200px;
    background: crimson;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
    transition: .5s;
  }
  
  .cv .liquid::after,
  .cv .liquid::before {
    content: '';
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    background: #000;
  }
  
  .cv .liquid::before {
    
    border-radius: 45%;
    background: rgba(20, 20, 20, 1);
    animation: animate 5s linear infinite;
  }
  
  .cv .liquid::after {
    
    border-radius: 40%;
    background: rgba(20, 20, 20, .5);
    animation: animate 10s linear infinite;
  }
  
  .cv:hover .liquid{
    top: -120px;
  }
  
  @keyframes animate {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }