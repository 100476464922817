@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700;900&family=Cinzel:wght@500;600&family=Pirata+One&family=Playfair+Display+SC:wght@400;900&family=Poltawski+Nowy:wght@500&family=Prata&display=swap');
  
  .app-form-button, .app-form-control {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Poltawski Nowy', serif;
    font-weight: 700;
    letter-spacing: 1.4px;
  }
  
  .background {
    display: flex;
    min-height: 100vh;
  }
  
  .container {
    flex: 0 1 700px;
    margin: auto;
    padding: 10px;
    margin-top: 8rem;
  }
  
  .screen {
    position: relative;
    background: #3e3e3e;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
    z-index: -1;
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #4d4d4f;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .screen-header-left {
    margin-right: auto;
  }
  
  .screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
  }
  
  .screen-header-button.close {
    background:  crimson;
  }
  
  .screen-header-button.maximize {
    background: #e8e925;
  }
  
  .screen-header-button.minimize {
    background: #74c54f;
  }
  
  .screen-header-right {
    display: flex;
  }
  
  .screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
  }
  
  .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1;
    padding: 50px;
  }
  
  .screen-body-item.left {
    display: flex;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color:  crimson;
    font-family: 'Poltawski Nowy', serif;

    font-size: 26px;
  }
  
  .app-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 25px;
    height: 4px;
    background:  crimson;
  }
  
  .app-contact {
    margin-top: auto;
    font-size: 0.7rem;
    color: #888;
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #ddd;
    font-size: 14px;
    /* text-transform: uppercase; */
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::placeholder {
    color: #666;
  }
  
  .app-form-control:focus {
    border-bottom-color: #ddd;
  }
  
  .app-form-button {
    background: none;
    border: none;
    color:  crimson;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  
  .app-form-button:hover {
    color:  rgb(255, 255, 255);
    transform: scale(1.2);
  }
  
  .credits {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color:  crimson;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    font-weight: normal;
  }
  
  .credits-link {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .dribbble {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  
  @media screen and (max-width: 520px) {
    .screen-body {
      flex-direction: column;
    }
  
    .screen-body-item.left {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
  }



  /* socials */ 



 
  
  .social {
    position: fixed;
    /* display:flex;
    align-items: flex-start; */
    top: 82%;
    left:-35%;
    font-family: 'Raleway', sans-serif;
    /* background: #4F8B89; */
    color: #fff;
  }

  .link {
    color: #fff;
    text-decoration: none;
  }

  .social ul {
    padding: 0px;
    transform: translate(-270px, 0);
  }
  .social ul li {
    display: block;
    margin: 5px;
    background: rgba(0, 0, 0, 0.36);
    width: 300px;
    text-align: right;
    padding: 10px;
    border-radius: 0 30px 30px 0;
    transition: all 1s;
  }
  .social ul li:hover {
    transform: translate(110px, 0);
    background: rgba(255, 255, 255, 0.4);
  }
  .social ul li:hover a {
    color: #000;
  }
  .social ul li:hover i {
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    transform: rotate(360deg);
    transition: all 1s;
  }
  .social ul li i {
    margin-left: 10px;
    color: crimson;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 20px;
    background: #ffffff;
    transform: rotate(0deg);
  }
  
  .modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Center both horizontally and vertically */
    width: 60%; /* Adjust the width as desired */
    max-width: 800px; /* Set a maximum width if needed */
    height: auto; /* Adjust the height as desired */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }