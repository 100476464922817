ul, ol {
    list-style: none;
}
a {
    text-decoration: none;
}
body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    line-height: 1.5;
}
.btn {
    display: inline-block;
    color: #fff;
    padding: 32px;
    position: relative;
    letter-spacing: 1px;
}
.btn__circle, .btn__text, .btn__white-circle {
    position: absolute;
}
.btn__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    box-shadow: 0 0 1px 1px #fff;
    transition: 0.3s linear;
}
.btn__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    transition: 0.3s ease-in-out;
}
.btn__white-circle svg {
    width: 24px;
    height: 24px;
    margin: auto;
}
.btn__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 24px 8px;
    transition: 0.3s linear;
}
.btn:hover .btn__circle {
    transform: scale(0);
}
.btn:hover .btn__white-circle {
    transform: translate(-50%, -50%) scale(1);
}
.btn:hover .btn__text {
    transform: translate(40px, -50%);
}
.content {
    background: black;
    /* height: 100vh; */
    /* width: 100vw; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 30px;
}