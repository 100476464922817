#logo{
  /* font-family: 'Cinzel Decorative', cursive; */
  font-family: 'Cinzel', serif;
  font-size: 2rem;
  padding: 2rem;
  color: white;
  cursor: pointer;
}

footer{
  color: #fff6f6;
  text-align: center;
  /* margin: 80px auto 20px; */
}
