.cont{
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 50%;
    margin: auto;
    padding: 11rem 0;
    /* display: flex;
    justify-content: center;
    align-content: center; */
  }
  
  .cont h1:nth-child(1) {
    color: #355c7d;
    font-family: 'Fira Code', monospace;
    font-weight: 800;
    font-size: 20px;
    margin: 0 0 0 35%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    width: 170px;
    animation: type 2s steps(40,end) forwards;
  }
  
  .cont h1:nth-child(2) {
    opacity: 0;
    font-family: "Work Sans", sans-serif;
    margin: 0 auto auto auto;
    /* background: linear-gradient(to right, #f8b195, #f67280, #c06c84); */
    background: crimson;
    font-weight: 800;
    font-size: 100px;  
    width: 480px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    animation: shring-animation 2.2s steps(40,end) 2s forwards, blink .5s step-end infinite alternate;
  }
  
  @keyframes shring-animation {
    0% {
      background-position: 0 0;
      opacity: 0;
      width: 0;
    }
    1% {
      background-position: 0 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
    50% {
      background-position: 150px 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
    100% {
      background-position: 400px 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
  }
  
  @keyframes type {
    0% {
      width: 0;
    }
    1%, 99%{
      border-right: 1px solid orange;
    }
    100%{
      border-right: none;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }


  /* Navbar css */

  body {
    background-color: #999;
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    background: rgb(0, 0, 0);
    font-family: sans-serif;
}
* {
    box-sizing: border-box;
}
section {
    position: relative;
}
.btn-pluss {
    overflow: hidden;
    position: relative;
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 22px;
    width: 30px;
    margin: 0 auto;
    background-color: white;
    transition: width 0.3s 0.5s ease, border-radius 1.1s ease;
}
.btn-pluss a {
    display: block;
    position: relative;
    color: #fa434b;
    text-decoration: none;
    overflow: hidden;
    padding: 5px;
    border-radius: 5px;
}
.btn-pluss a:hover {
    text-decoration: inherit;
    color: white;
    background-color: #fa434b;
    transition: background-color 0.5s ease;
}
.btn-pluss:after {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    line-height: 20px;
    text-align: center;
    font-size: 1.1rem;
    background-color: #fa434b;
    color: white;
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.3s 0.5s ease;
    cursor: pointer;
    cursor: hand;
}
.btn-pluss ul {
    opacity: 0;
}
.btn-pluss ul {
    margin-top: 15px;
    opacity: 0;
    width: 100%;
    margin-left: 0px;
    transition: all 0.5s ease;
    text-align: center;
    font-size: 0.9rem;
    
}
.btn-pluss ul li {
    background-color: #e4e4e4;
    margin-top: 5px;
    border-radius: 5px;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 1s ease;
}
.tooltip {
    position: relative;
    padding: 5px;
    border-radius: 5px;
    width: 70px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 15px;
    animation-duration: 3s;
    animation-name: jump;
    animation-iteration-count: infinite;
    background-color: #fff;
    color: #fa434b;
    transition: all 1s ease;
    pointer-events: none;
}
.tooltip::after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    display: block;
    height: 10px;
    width: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: inherit;
}
.tooltip::before {
    content: '';
    color: white;
}
.btn-pluss-wrapper:hover .tooltip {
    animation-duration: 0s;
    transition: all 1s ease;
    color: white;
    width: 90px;
}
.btn-pluss-wrapper:hover .tooltip::before {
    content: 'Choose!';
    display: block;
    color: #fa434b;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    transition: all 1s 0.3s ease;
}
.btn-pluss-wrapper:hover .btn-pluss {
    width: 150px;
    border-radius: 15px;
    padding-bottom: 5px;
    transition: width 0.3s ease, border-radius 0.3s ease, padding-bottom 0.3s ease;
}
.btn-pluss-wrapper:hover .btn-pluss::after {
    transition: all 0.3s ease;
    left: 50%;
    top: 10px;
    transform: translateY(-5px) translateX(-50%);
}
.btn-pluss-wrapper:hover .btn-pluss ul {
    opacity: 1;
    margin-top: 30px;
    transition: all 1s ease;
}
.btn-pluss-wrapper:hover .btn-pluss li {
    height: 25px;
    transition: height 1s ease;
}
.btn-pluss-wrapper:hover .btn-pluss li:hover {
    border-bottom: 1px solid #d2c9c9;
}
@keyframes jump {
    0% {
        transform: translateY(3px);
   }
    50% {
        transform: translateY(-15px);
   }
    100% {
        transform: translateY(3px);
   }
}

.btn-pluss-wrapper{
    padding-top: 1.9rem;
}