/* card1

@import url('https://fonts.googleapis.com/css?family=Krub:400,700');

*/
/* html, body {
	 width: 100%;
	 height: 100vh;
} */

.aboutMe{
	/* height: 100vh; */
    display: flex;
    flex-direction: column;
	justify-content: center
}

.cards{
	display: flex;
	gap: 4rem;
	justify-content: center;
	margin: 1rem;
	align-items: center;
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
	
}
 .card1 {
	 /* position: absolute; */
	 /* margin: auto; */
	 /* top: 0;
	 right: 30%;
	 bottom: 0;
	 left: 0; */
	 width: 300px;
	 height: 500px;
	 border-radius: 10px;
	 box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.2);
	 background: #151515;
	 /* overflow: auto; */
	 font-family: 'Krub', sans-serif;

     /* display: flex; */
}
 .card1 .ds-top {
	 /* position: absolute; */
	 margin: auto;
	 /* top: 0;
	 right: 0;
	 left: 0; */
	 width: 300px;
	 height: 30px;
	 background: crimson;
	 animation: dsTop 1.5s;
	 border-top-left-radius: 10px;
	 border-top-right-radius: 10px;

}
 .card1 .avatar-holder {
	 /* position: absolute; */
	 margin: auto;
	 /* top: 40px;
	 right: 0;
	 left: 0; */
     /* margin-top: auto; */
	 width: 100px;
	 height: 100px;
	 border-radius: 50%;
	 box-shadow: 0 0 0 5px #151515, inset 0 0 0 5px #000, inset 0 0 0 5px #000, inset 0 0 0 5px #000, inset 0 0 0 5px #000;
	 background: white;
	 overflow: hidden;
	 animation: mvTop 1.5s;
}
 .card1 .avatar-holder img {
	 width: 100%;
	 height: 100%;
	 object-fit: cover;
}
 .card1 .name {
	 /* position: absolute; */
	 /* margin: auto; */
	 /* top: -10rem;
	 right: 0;
	 bottom: 0;
	 left: 0; */
	 width: inherit;
	 height: 40px;
	 text-align: center;
	 animation: fadeIn 2s ease-in;
}
 .card1 .name a {
	 color: white;
	 text-decoration: none;
	 font-weight: 700;
	 font-size: 18px;
}
 .card1 .name a:hover {
	 /* text-decoration: underline; */
	 color: crimson;
}
 .card1 .name h6 {
	 /* position: absolute; */
	 margin: auto;
	 /* left: 0;
	 right: 0;
	 bottom: 0; */
	 color: white;
	 width: 40px;
}
 .card1 .button {
	 /* position: absolute; */
	 margin: auto;
	 padding: 8px;
	 /* top: -7.2rem;
	 right: 0;
	 bottom: 0;
	 left: 0; */
	 width: inherit;
	 height: 40px;
	 text-align: center;

	 animation: fadeIn 2s ease-in;
	 outline: none;
}
 .card1 .button a {
	 padding: 5px 20px;
	 border-radius: 10px;
	 color: white;
	 letter-spacing: 0.05em;
	 text-decoration: none;
	 font-size: 10px;
	 transition: all 1s;
}
 .card1 .button a:hover {
	 color: white;
	 background: crimson;
}
 .card1 .ds-info {
	 /* position: absolute; */
	 margin-left: 23px;
	 /* top: -3.2rem; */
	 /* bottom: 0; */
	 /* right: 0; */
	 /* left: 0; */
	 width: inherit;
	 height: 0.5rem;
	 display: flex;
     align-items: center;
}
 .card1 .ds-info .pens, .card .ds-info .projects, .card .ds-info .posts {
	 position: relative;
	 left: -300px; 
	 width: calc(250px / 3);
	 text-align: center;
	 color: white;
	 animation: fadeInMove 2s;
	 animation-fill-mode: forwards;
}
 .card1 .ds-info .pens h6, .card .ds-info .projects h6, .card .ds-info .posts h6 {
	 text-transform: uppercase;
	 color: crimson;
}
 .card1 .ds-info .pens p, .card .ds-info .projects p, .card .ds-info .posts p {
	 font-size: 12px;
}
 .card1 .ds-info .ds:nth-of-type(2) {
	 animation-delay: 0.5s;
}
 .card1 .ds-info .ds:nth-of-type(1) {
	 animation-delay: 1s;
}

 .pens:hover{
    cursor: pointer;
    transform:scale(1.5)
}
 .card1 .ds-skill {
	 /* position: absolute; */
	 margin: auto;
	 /* bottom: 9.6rem;
	 right: 0;
	 left: 0; */
	 width: 250px;
	 height: 100px;
	 animation: mvBottom 1.5s;
     text-align: center;
}
 .card1 .ds-skill h4 {
	 margin-bottom: 5px;
	 font-weight: 700;
	 text-transform: uppercase;
	 color: crimson;
}
 .card1 .ds-skill .skill h6 {
	 font-weight: 400;
	 font-size: 8px;
	 letter-spacing: 0.05em;
	 margin: 4px 0;
	 color: white;
}
 .card1 .ds-skill .skill .fab {
	 color: crimson;
	 font-size: 14px;
}
 .card1 .ds-skill .skill .bar {
	 height: 5px;
	 background: crimson;
	 text-align: right;
}
 .card1 .ds-skill .skill .bar p {
	 color: white;
	 font-size: 8px;
	 padding-top: 5px;
	 animation: fadeIn 5s;
}
 .card1 .ds-skill .skill .bar:hover {
	 background: white;
}
 .card1 .ds-skill .skill .bar-html {
	 width: 95%;
	 animation: htmlSkill 1s;
	 animation-delay: 0.4s;
}

#about{
    font-size: 0.75rem;
    text-align: center;
    margin: 0.5rem;
    color: white;
}

.ds{
    font-size: 1.3rem;
}

 @keyframes fadeInMove {
	 0% {
		 opacity: 0;
		 left: -300px;
	}
	 100% {
		 opacity: 1;
		 left: 0;
	}
}
 @keyframes fadeIn {
	 0% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes htmlSkill {
	 0% {
		 width: 0;
	}
	 100% {
		 width: 95%;
	}
}
 
 @keyframes mvBottom {
	 0% {
		 bottom: -150px;
	}
	 100% {
		 bottom: 10px;
	}
}
 @keyframes mvTop {
	 0% {
		 top: -150px;
	}
	 100% {
		 top: 40px;
	}
}
 @keyframes dsTop {
	 0% {
		 top: -150px;
	}
	 100% {
		 top: 0;
	}
}
 .following {
	 color: white;
	 background: crimson;
}

.link{
    text-decoration: none;
    color: crimson;
}
 







/* SKILLS */


 .card {
	 /* position: absolute; */
	 /* margin: auto; */
	 /* top: 0;
	 right: 0;
	 bottom: 0;
	 left: 30%; */
	 width: 300px;
	 height: 500px;
	 border-radius: 10px;
	 box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.2);
	 background: #151515;
	 overflow: auto;
     /* display: flex; */
}

 
 .card .ds-info {
	 /* position: absolute; */
	 margin: auto;
	 /* top: 120px;
	 bottom: 0;
	 right: 0;
	 left: 0; */
	 width: inherit;
	 height: 40px;
	 display: flex;
}

 .card .ds-info .pens h6, .card .ds-info .projects h6, .card .ds-info .posts h6 {
	 text-transform: uppercase;
	 color: crimson;
}
 .card .ds-info .pens p, .card .ds-info .projects p, .card .ds-info .posts p {
	 font-size: 12px;
}
 .card .ds-info .ds:nth-of-type(2) {
	 animation-delay: 0.5s;
}
 .card .ds-info .ds:nth-of-type(1) {
	 animation-delay: 1s;
}
 .card .ds-skill {
	 /* position: absolute; */
	 margin: auto;
     margin-top: 2rem;
	 /* bottom: 22rem;
	 right: 0;
	 left: 0; */
	 width: 250px;
	 height: 100px;
	 animation: mvBottom 1.5s;
}
 .card .ds-skill h6 {
	 margin-bottom: 5px;
	 font-weight: 700;
	 text-transform: uppercase;
	 color: crimson;
}
 .card .ds-skill .skill h6 {
	 font-weight: 400;
	 font-size: 8px;
	 letter-spacing: 0.05em;
	 margin: 4px 0;
	 color: white;
}
 .card .ds-skill .skill .fab {
	 color: crimson;
	 font-size: 14px;
}
 .card .ds-skill .skill .bar {
	 height: 5px;
	 background: crimson;
	 text-align: right;
}
 .card .ds-skill .skill .bar p {
	 color: white;
	 font-size: 8px;
	 padding-top: 5px;
	 animation: fadeIn 5s;
}
 .card .ds-skill .skill .bar:hover {
	 background: white;
}
 .card .ds-skill .skill .bar-html {
	 width: 95%;
	 animation: htmlSkill 1s;
	 animation-delay: 0.4s;
}
 .card .ds-skill .skill .bar-css {
	 width: 90%;
	 animation: cssSkill 2s;
	 animation-delay: 0.4s;
}
 .card .ds-skill .skill .bar-js {
	 width: 75%;
	 animation: jsSkill 3s;
	 animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-rb {
    width: 81%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-ts {
    width: 73%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-py {
    width: 73%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-jsx {
    width: 72%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-nd {
    width: 71%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-ex {
    width: 70%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-fk {
    width: 66%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}
.card .ds-skill .skill .bar-dj {
    width: 63%;
    animation: jsSkill 3s;
    animation-delay: 0.4s;
}

#skill{
    font-size: 0.8rem;
}
 @keyframes fadeInMove {
	 0% {
		 opacity: 0;
		 left: -300px;
	}
	 100% {
		 opacity: 1;
		 left: 0;
	}
}
 @keyframes fadeIn {
	 0% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes htmlSkill {
	 0% {
		 width: 0;
	}
	 100% {
		 width: 95%;
	}
}
 @keyframes cssSkill {
	 0% {
		 width: 0;
	}
	 100% {
		 width: 90%;
	}
}
@keyframes rbSkill {
    0% {
        width: 0;
   }
    100% {
        width: 81%;
   }
}

 @keyframes jsSkill {
	 0% {
		 width: 0;
	}
	 100% {
		 width: 75%;
	}
}

@keyframes tsSkill {
    0% {
        width: 0;
   }
    100% {
        width: 73%;
   }
}
@keyframes pySkill {
    0% {
        width: 0;
   }
    100% {
        width: 73%;
   }
}
@keyframes jsxSkill {
    0% {
        width: 0;
   }
    100% {
        width: 72%;
   }
}
@keyframes ndSkill {
    0% {
        width: 0;
   }
    100% {
        width: 71%;
   }
}
@keyframes exSkill {
    0% {
        width: 0;
   }
    100% {
        width: 70%;
   }
}
@keyframes fkSkill {
    0% {
        width: 0;
   }
    100% {
        width: 66%;
   }
}
@keyframes djSkill {
    0% {
        width: 0;
   }
    100% {
        width: 63%;
   }
}

 @keyframes mvBottom {
	 0% {
		 bottom: -150px;
	}
	 100% {
		 bottom: 10px;
	}
}
 @keyframes mvTop {
	 0% {
		 top: -150px;
	}
	 100% {
		 top: 40px;
	}
}
 @keyframes dsTop {
	 0% {
		 top: -150px;
	}
	 100% {
		 top: 0;
	}
}
 .following {
	 color: white;
	 background: crimson;
}



/* portfolio download/ */


