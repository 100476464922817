




/* .projo{

    margin-top: 7%;

}   */


.projo {
    color: #e6e6e6;
    font-family: 'Lato', sans-serif;
  }
  
  h1, h2 {
    font-family: 'Fjalla One', sans-serif;
    /* margin-top: 6%; */
    text-transform: uppercase;
  }
  
  figure {
    margin: 0;
  }
  
  img {
    height: auto;
    width: 100%;  
  }
  
  .wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(100px, auto);
    /* margin: 24px; */
    max-width: 1000px;
    position: relative;
    /* margin-top: 30%; */
    /* overflow: auto; */
  }
  
  .wrapper > * {
    grid-column: 1 / span 2;
  }
  
  .header {
    font-size: 1.5em;
    grid-row: 1 / 2;
    /* grid-row: 2 / 2; */

  }
  
  header:after {
    background: crimson;
    content: "";
    display: block;
    height: 20px;
    width: auto;
  }
  
  .article {
    text-align: right;
  }
  
  .featured-image-1 {
    grid-row: 2 / 3;
  }
  
  .number-1 {
    grid-row: 2 / 4;
  }
  
  .featured-image-2, .number-2 {
    grid-row: 4 / 5;
  }
  
  .number-1, .number-4 {
    align-self: end;
    color: rgba(104,23,186,0.5);
    font-family: 'Fjalla One', sans-serif;
    font-size: 480px;
    line-height: 480px;
    grid-column: 1 / 2;
  }
  
  .article-1, .article-4 {
    grid-column: 2 / 3;
  }
  
  .featured-image-2, .number-2 {
    align-self: end;
  }
  
  .number-2 {
    color: #eee;
    font-size: 200px;
    justify-self: end;
    line-height: 160px;
  }
  
  .number-3 {
    background: black;
    border-radius: 130px;
    color: white;
    font-family: 'Fjalla One', sans-serif;
    font-size: 100px;
    height: 130px;
    line-height: 130px;
    position: absolute;
    grid-row: 6 / 7;
    right: -20px;
    text-align: center;
    top: -20px;
    width: 130px;
  }
  
  .featured-image-3 img {
    height: 100%;
  }
  
  .number-4 {
    font-size: 320px;
    left: -20px;
    line-height: 320px;
    position: absolute;
  }
  
  @media (min-width: 600px) {
    
    .wrapper {
      grid-template-columns: repeat(2, 1fr 2fr);
      margin: 24px auto;
    }
    
    .header {
      grid-column: 3 / 5;
      width: 70%;
    }
    
    .featured-image-1 {
      grid-row: 1 / 3;
    }
    
    .article-1 {
      grid-row: 3 / 4;
    }
    
    .article-2 {
      grid-column: 4 / 5;
      grid-row: 4 / 5;
    }
    
    .featured-image-2, .number-2 {
      grid-column: 3 / 5;
      grid-row: 2 / 4;
    }
  
    .featured-image-3, .number-3 {
      grid-row: 4 / 6;
    }
    
    .featured-image-4, .number-4 {
      grid-column: 3 / 5;
      grid-row: 5 / 6;
    }
    
    .number-4 {
      font-size: 320px;
      grid-row: 5 / 7;
    }
    
    .article-3, .article-4 {
      grid-row: 6 / 7;
    }
    
    .article-4 {
      grid-column: 4 / 5;
    }
    
  }

  .txt{
    text-align: center;
  }